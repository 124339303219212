import axios from "axios";

function actionHandler(payload) {
	return new Promise((resolve, reject) => {
		axios(payload)
			.then(response => {
				let resp = response.data;
				if (response.status >= 200 && response.status < 300) {
					resolve(resp.data || resp.result);
				} else {
					reject(resp.message);
				}
			})
			.catch(err => {
				console.error(err);
				reject(getActionErrorMessage(err));
			})
	});
}

function getActionErrorMessage(err) {
	if (err.response?.data?.message) {
		return "Error: " + err.response.data.message;
	} else {
		return err;
	}
}

export default {
	baseURL: 'https://dev.enfono.com/api_kabco/',

	loginURL: 'auth/login/',
	logoutURL: 'auth/logout/',

	stateURL:'web/v1/states/',
	regionURL: 'web/v1/regions/?state__id=1',
	sub_regionURL:"web/v1/sub_regions/?region__id={region}",
	
	cropsExpertsListURL: "web/v1/crops/experts/?search={searchQuery}",
	cropsExpertDetailsURL: "web/v1/crops/expert/{id}/",

	cropVarietiesListURL: "web/v1/crops/crop_variety/?crop={crop}&search={searchQuery}&page_size=1000",
	cropVarietyDetailsURL: "web/v1/crops/crop_variety/{id}/",

	farmingPracticeListURL: "web/v1/crops/farming_practice/?crop={crop}&search={searchQuery}",
	farmingPracticeDetailsURL: "web/v1/crops/farming_practice/{id}/",

	diseasesListURL: "web/v1/crop/dieseases/?crop={crop}&search={searchQuery}",
	diseasesDetailsURL: "web/v1/crops/diesease/{id}/",

	fetchPestCauseURL: "web/v1/crop/{id}/pest_cause/",
	fetchNutrientCauseURL: "web/v1/crop/{id}/nutrient_cause/",
	fetchPesticideURL:"web/v1/crop/{id}/pesticide",

	nutrientsListURL: "web/v1/crops/nutrients/?crop={crop}&search={searchQuery}&page_size=100",
	nutrientDetailsURL: "web/v1/crop/nutrient/{id}/",
	
	marketPriceListURL: "web/v1/crops/market_price/?crop={crop}&start_date={start_date}&end_date={end_date}",
	marketPriceDetailsURL: "web/v1/crops/market_price/{id}/",

	fertilizersListURL: "web/v1/crops/fertilizer/?crop={crop}&search={searchQuery}",
	fertilizersDetailsURL: "web/v1/crops/fertilizer/{id}/",

	fetchAdvisoriesListURL: "web/v1/crops/institutional_advisor/?crop={crop}&search={searchQuery}",
	fethAdvisoriesDetailsURL: "web/v1/crops/institutional_advisor/{id}/",

	fetchPesticideListURL: "web/v1/crops/pestiside/?crop={crop}&search={searchQuery}",
	fetchPesticideDetailsURL: "web/v1/crop/pestiside/{id}/",

	fetchPestsAndAttacksListURL: "web/v1/crops/pest_and_attacks?crop={crop}&search={searchQuery}",
	fetchPestsAndAttacksDetailsURL: "web/v1/crops/pest_and_attack/{id}/",

	userListURL: "web/v1/users/",
	userDetailsURL: "web/v1/user/{id}/",
	resetPasswordURL:"web/v1/user/{id}/user_password_reset/",
	
	landDropdownURL: "web/v1/farmer/{id}/land_dropdown",
	cropDropDownURL:"web/v1/land/{id}/crop_dropdown",

	farmerListURL: "web/v1/farmers/?page_size={page_size}&page={page}&region={region}&sub_region={sub_region}",
	addFarmerURL: "web/v1/farmers/",
	farmerLandDetailsURL: "web/v1/farmer/{id}/lands/",
	updateFarmerlandDetails:"web/v1/farmer/{user_id}/land/{id}/",
	farmerProfileURL: "web/v1/farmer/{id}/",
	farmerProfileUploadURL:"web/v1/farmer/{id}/profile_photo/",


	agriInputListURL: "web/v1/farmer/{user_id}/agri_input/{id}/?land={land_id}&crop={crop}&search={searchQuery}&start_date={startDate}&end_date={endDate}",
	deleteAgriInputURL: "web/v1/farmer/{user_id}/agri_input/{id}/",
	cropYieldListURL: "web/v1/farmer/{user_id}/crop_yield/{id}/?land={land_id}&crop={crop}",
	deleteCropYieldURL: "web/v1/farmer/{user_id}/crop_yield/{id}/",
	
	recommendationListURL: "web/v1/farmer/{user_id}/recommendations/{id}/?land={land_id}&status={status}&search={searchQuery}&start_date={startDate}&end_date={endDate}&crop={crop}",
	recommendationDetailsURL: "web/v1/farmer/{user_id}/recommendation/{id}/",
	verifyRecommendationURL: "web/v1/recommendation/{id}/verify/",

	productivityListURL: "web/v1/farmer/{user_id}/productivity/?search={searchQuery}&land={land}&crop={crop}",
	productivityDetailsURL: "web/v1/farmer/{user_id}/productivity/{id}/",

	incomeAndExpensesListURL: "web/v1/farmer/{id}/income_expenses/?start_date={startDate}&end_date={endDate}&land={land}",
	incomeAndExpensesDetailsURL: "web/v1/farmer/{user_id}/income_expense/{id}/",

	costOptimizeListURL: "web/v1/farmer/{id}/cost_optimizations/?start_date={startDate}&end_date={endDate}&land={land}&crop={crop}",
	costOptimizeDetailsURL: "web/v1/farmer/{user_id}/cost_optimization/{id}/",

	incidentListURL: "web/v1/farmer/{id}/incidents/?search={searchQuery}&start_date={startDate}&end_date={endDate}&land={land}&crop={crop}",
	incidentDetailsURL: "web/v1/farmer/{user_id}/incident/{id}/",

	farmIntercroppingsListURL: 'web/v1/farmer/{id}/inter_croppings/',
	farmIntercroppingsDetailsURL: 'web/v1/farmer/{user_id}/inter_croping/{id}/',
	
	farmLocalVarietiesListURL: 'web/v1/farmer/{id}/local_varieties/?crop={crop}&land={land}	',
	farmLocalVarietyDetailsURL: 'web/v1/farmer/{user_id}/local_variety/{id}/',
	
	farmImprovedVarietiesListURL: 'web/v1/farmer/{id}/improved_varieties/?crop={crop}&land={land}',
	farmImprovedVarietyDetailsURL: 'web/v1/farmer/{user_id}/improved_variety/{id}/',

	farmSoilReportListURL: 'web/v1/farmer/{id}/soil_report/?land={land}',
	farmSoilReportDetailsURL: 'web/v1/farmer/{user_id}/soil_report/{id}/',
	
	farmMiscellaneousCropListURL: 'web/v1/farmer/{id}/miscellaneous_farm_data_of_crop/?farm_data_type={data_type}&crop={crop}&land={land}',
	farmMiscellaneousLandListURL:'web/v1/farmer/{id}/miscellaneous_farm_data_of_land/?farm_data_type={data_type}&land={land}',
	farmMiscellaneousCropDetailsURL: 'web/v1/farmer/{user_id}/miscellaneous_farm_data_of_crop/{id}/',
	farmMiscellaneousLandDetailsURL: 'web/v1/farmer/{user_id}/miscellaneous_farm_data_of_land/{id}/',
	farmSatelliteListURL: "web/v1/farmer/{id}/satellite_data/?land={land}",
	farmSatelliteDetailsURL: "web/v1/farmer/{user_id}/satellite_data/{id}/",
	uploadSatelliteFile: "web/v1/farmer/satellite_data_file/",
	deleteLandFilURL: "web/v1/farmer/miscellaneous_farm_data_file_of_land/{id}/",
	deleteCropFilURL: "web/v1/farmer/miscellaneous_farm_data_file_of_crop/{id}/",
	deleteSatelliteFilURL:"web/v1/farmer/satellite_data_file/{id}/",

	uploadCropMiscFile: "web/v1/farmer/miscellaneous_farm_data_of_crop_upload/",
	uploadLandMiscFile: "web/v1/farmer/miscellaneous_farm_data_of_land_upload/",
	fetchMiscCropFileListURL: "web/v1/farmer/{id}/miscellaneous_farm_data_of_crop_file_list/?farm_data_type={farm_data_type}",
	fetchMiscLandFileListURL: "web/v1/farmer/{id}/miscellaneous_farm_data_of_land_file_list/?farm_data_type={farm_data_type}",
	fetchSatelliteFile:"web/v1/farmer/{id}/satellite_data_file/",
		
	diseaseHistoryListURL: "web/v1/farmer/{id}/diesease/?crop={crop}&land={land}",
	diseaseHistoryDetailsURL: "web/v1/farmer/{user_id}/dieseas/{id}/",

	fvrListURL: "web/v1/farmer/{id}/fvr/",
	fvrDetailsURL: "web/v1/farmer/{user_id}/fvr/{id}/",

	feedbackListURL: "web/v1/farmer/{id}/feedback/",
	feedbackDetailsURL: "web/v1/farmer/{user_id}/feedback/{id}/",

	newsListURL: "web/v1/news/",
	newsDetailsURL: "web/v1/news/{id}/",

	schemeListURL: "web/v1/scheme/",
	schemeDetailsURL: "web/v1/scheme/{id}/",

	reportsListURL: "web/v1/reports/?search={searchQuery}&start_date={startDate}&end_date={endDate}",
	reportsDetailsURL: "web/v1/report/{id}/",

	dataListURL: "web/v1/data/?start_date={startDate}&end_date={endDate}&search={searchQuery}",
	dataDetailsURL: "web/v1/data/{id}/",

	costCategoriesListURL: "web/v1/farmer/cost_categories/?type={type}",
	
	cropListURL: "web/v1/all_crops/",
	
	executiveListURL: "web/v1/executives/",
	executiveProfileURL: "web/v1/executive/{id}/",
	attendanceListURL: "web/v1/executive/{id}/attendance/",
	taskListURL: 'web/v1/executive/{id}/tasks/',
	exeLocationURL:"web/v1/executive/{id}/location?date={date}",
	taskDetailsURL: "web/v1/executive/{executive_id}/task/{id}/",
	farmerCountURL: "web/v1/executive/{id}/farmer_count/",
	exeFarmerListURL:"web/v1/executive/{id}/farmers/",

	dashboardURL: 'web/v1/dashboard/',
	dashboardTaskListURL: "web/v1/tasks_to_verify/",
	dashboardTaskDetailsURL: "web/v1/tasks_to_verify/{id}",
	
	subscriptionListURL: "web/v1/farmers/{id}/subscription",
	subscriptionDetailURL:"web/v1/farmers/{user_id}/subscription/{id}/",
	
	//Executive Dashboard

	executiveAttendanceListURL: "executive/attendance/",
	executiveFarmerCount:"executive/farmer_count/",
	executiveFarmerListURL: "executive/farmers/",
	exeFarmerPostURL:'executive/web/farmers/',
	executiveFarmerProfile: 'executive/web/farmer/{id}/',


	executiveLocalVarietiesURL: "executive/web/farmer/{id}/local_varieties/",
	executiveImprovedVarietiesURL: "executive/web/farmer/{id}/improved_varieties/",
	executiveSoilReportURL: "executive/web/farmer/{id}/soil_reports/",
	executiveCropMiscellaneousURL:"executive/web/farmer/{id}/miscellaneous_farm_data_of_crop/",
	executiveLandMiscellaneousURL: "executive/web/farmer/{id}/miscellaneous_farm_data_of_land/",
	executiveSatelliteListURL: "executive/web/farmer/{id}/satellite_data/",
	executiveCropMiscellaneousFileURL: "executive/web/farmer/{id}/miscellaneous_farm_data_of_crop_file_list/",
	executiveLandMiscellaneousFileURL: "executive/web/farmer/{id}/miscellaneous_farm_data_of_land_file_list/",
	executiveSatelliteFileURL:"executive/web/farmer/{id}/satellite_data_file/",
		
	
	executiveAgriInputURL: "executive/web/farmer/{user_id}/agri_inputs/",
	
	executiveCropYieldListURL: "executive/web/farmer/{user_id}/crop_yields/",

	executiveRecommendationListURL: "executive/web/farmer/{user_id}/recommendations/",

	executiveProductivityURL: "executive/web/farmer/{user_id}/productivity/",

	executiveDiseaseHistoryURL: "executive/web/farmer/{id}/diseases/",
	executiveIncidentsListURL: "executive/web/farmer/{id}/incidents/",
	executiveIncidentDetailsURL: "executive/web/farmer/{user_id}/incident/{id}/",

	executiveSubscriptionListURL: "executive/web/farmer/{id}/subscription",
	executiveSubscriptionDetailsURL: "executive/web/farmer/{user_id}/subscription/{id}/",

	executiveFvrListURL: "executive/web/farmer/{id}/fvr/",
	executiveFvrDetailsURL: "executive/web/farmer/{user_id}/fvr/{id}/",
	executiveFeedbackListURL: "executive/web/farmer/{id}/feedback/",
	executiveFeedbackDetailsURL: "executive/web/farmer/{user_id}/feedback/{id}/",
	
	executiveIncomeExpenseListURL: "executive/web/farmer/{id}/income_expenses/",
	executiveIncomeExpenseDetailsURL: "executive/web/farmer/{user_id}/income_expense/{id}/",
	executiveCost_optimizationListURL: 'executive/web/farmer/{id}/cost_optimizations/',
	executiveCost_optimizationDetailsURL: 'executive/web/farmer/{user_id}/cost_optimization/{id}/',

	executiveFarmerImagesURL: "executive/web/farmer/{id}/farm_visit_image/?crop={crop}&crop_variety={crop_variety}&crop_part={crop_part}",
	executiveFarmerCropListURL: "executive/farmer/{id}/crops",
	executiveFarmerCropPartURL: "executive/crop_parts/",
	executiveCropVarietyListURL:"executive/farmer/crops/{id}/crop_varieties",
	

	farmerReportURL:"web/v1/farmer/{id}/full_data/",

	//ECommerce Dashboard
	ordersListURL: "e_commerce/admin/orders/?order_status={orderStatus}",
	productListURL: "e_commerce/admin/products/",
	categoryListURL: "e_commerce/admin/category/",
	dashboardDataURL: "e_commerce/admin/dashboard/",
	categoryDetailURL: 'e_commerce/admin/category/{id}/',
	productDetailsURL: "e_commerce/admin/product/{id}/",
	orderDetailURL: "e_commerce/admin/order/{id}/",
	uploadProductImageURL:"e_commerce/admin/products/{id}/image",
	farmerProductsURL: "e_commerce/admin/farmer_products/",
	deleteImageURL:"e_commerce/admin/products/{product_id}/image/{id}/",
	actionHandler
}