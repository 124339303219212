<template>
  <div class="header header-background">
    <div class="container-fluid">
      <div class="header-body">
        <slot></slot>
      </div>
    </div>
  </div>
</template>
<script>
  export default {
    name: 'base-header',
    props: {
      type: {
        type: String,
        default: 'primary',
        description: 'Header background type'
      }
    }
  }
</script>
<style>
.header-background {
  background: #055890;
}
</style>
