import api from "@/api";

const backendToFrontendFormatter = originalData => {
	const data = JSON.parse(JSON.stringify(originalData));

	if (data.profilePicture === undefined) {
		data.profilePicture = 'https://upload.wikimedia.org/wikipedia/commons/9/99/Sample_User_Icon.png';
	}

	data.userType = data.user_type;
	delete data.user_type;

	return data;
};

/* const frontendToBackendFormatter = originalData => {
	const data = JSON.parse(JSON.stringify(originalData));
	return data;
}; */

export default {
	state() {
		return {
			userTypes: ['admin', 'kabco_official'],
			userList: [
				/* {
					id: 10,
					name: 'Genos',
					profilePicture: 'https://upload.wikimedia.org/wikipedia/commons/9/99/Sample_User_Icon.png',
					userType: 'executive',
					mobileNo: '91333333333',
					address: 'House 123, Street ABC',
					password: '12345678',
					username: 'Demon Cyborg',
					isBlocked: false,
				} */
			]
		};
	},
	mutations: {
		updateUserList(state, userList) {
			state.userList = userList.map(user => backendToFrontendFormatter(user));
		},
		updateUser(state, { userData }) {
			let data = backendToFrontendFormatter(userData);
			const index = state.userList.findIndex(item => item.id == userData.id);
			if (userData.id == undefined) {
				state.userList.push(data)
			} else {
				state.userList.splice(index,1,data)
			}
			
		},
		deleteUser(state, index) {
			state.userList.splice(index, 1)
		}
	},
	actions: {
		fetchUserList({ commit }) {
			return new Promise((resolve, reject) => {
				api.actionHandler({
					url: api.userListURL,
					method: "GET"
				}).then(userList => {
					commit('updateUserList', userList);
					resolve();
				}).catch(errMsg => reject(errMsg))
			});
		},
		fetchUserDetails({ commit },userID) {
			return new Promise((resolve, reject) => {
				api.actionHandler({
					url: api.userDetailsURL.replace("{id}",userID),
					method: "GET"
				}).then(userData => {
					commit('updateUser', { userData });
					resolve();
				}).catch(errMsg => reject(errMsg))
			});
		},
		addUser({ commit }, userObj) {
			let userEdit = userObj.id != undefined;
			const userID = userObj.id;
			return new Promise((resolve, reject) => {
				api.actionHandler({
					url:userEdit ?api.userDetailsURL.replace("{id}",userID): api.userListURL,
					method: userEdit ? "PATCH":"POST",
					data:userObj
				}).then(userData => {
					commit('updateUser', { userData });
					resolve();
				}).catch(errMsg => reject(errMsg))
			});
		},
		deleteUser({ commit }, { id, index }) {
			return new Promise((resolve, reject) => {
				api.actionHandler({
					url: api.userDetailsURL.replace("{id}",id),
					method: 'DELETE',
				}).then(() => {
					commit('deleteUser', index);
					resolve();
				}).catch(errMsg => reject(errMsg));
			});
		},
		resetUserPassword({ commit }, { userID, data }) {
			return new Promise((resolve, reject) => {
				api.actionHandler({
					url: api.resetPasswordURL.replace("{id}",userID),
					method: 'PUT',
					data:data
				}).then(() => {
					commit('resetPassword');
					resolve();
				}).catch(errMsg => reject(errMsg));
			});
		}
	}
};
