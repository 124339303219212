import api from "@/api";
import axios from 'axios';
const backendToFrontendFormatter = originalData => {
	let scheme = JSON.parse(JSON.stringify(originalData));
	
	return scheme;
};

// const frontendToBackendFormatter = originalData => {
// 	let scheme = JSON.parse(JSON.stringify(originalData));
	
// 	return scheme.schemeData
// };

export default {
	state() {
		return {
			loaded: false,
			schemeList: [
				{
					tabTitle: "Loading...",
					tabContents: [
						{
							title: "Loading...",
							content: "Loading..."
						}
					]
				}
			]
		};
	},
	mutations: {
		updateSchemeList(state, { schemeList }) {
			state.schemeList = [];
			schemeList.results.forEach(scheme =>
				state.schemeList.push(backendToFrontendFormatter(scheme))
			);
			state.loaded = true;
		},
		updateSchemeDetails(state, { schemeDetails }) {
			const index = state.schemeList.findIndex(
				data => data.id == schemeDetails.id
			);
			let data = backendToFrontendFormatter(schemeDetails);
			if (index != -1) {
				state.schemeList.splice(index, 1, data);
			} else {
				state.schemeList.push(data);
			}
		},
		deleteScheme(state, index) {
			state.schemeList.splice(index, 1);
		}
	},
	actions: {
		fetchSchemeList({ commit }) {
			return new Promise((resolve, reject) => {
				api.actionHandler({
					url: api.schemeListURL,
					method: "GET"
				})
					.then(schemeList => {
						commit("updateSchemeList", { schemeList });
						resolve();
					})
					.catch(errMsg => reject(errMsg));
			});
		},
		fetchSchemeDetails({ commit }, schemeID) {
			return new Promise((resolve, reject) => {
				api.actionHandler({
					url: api.schemeDetailsURL.replace("{id}", schemeID),
					method: "GET"
				})
					.then(schemeDetails => {
						commit("updateSchemeDetails", { schemeDetails });
						resolve();
					})
					.catch(errMsg => reject(errMsg));
			});
		},
		setSchemeDetails({ commit }, actionObject) {
			let editMode = actionObject.schemeData.id != undefined;
			if(!editMode){
				return new Promise((resolve, reject) => {
					axios.post(api.schemeListURL,
						actionObject.schemeData,{
							headers: {
								'Content-Type': 'multipart/form-data;boundary=----WebKitFormBoundary7MA4YWxkTrZu0gW'
							}
						}
					).then(schemeDetails => {
						commit("updateSchemeDetails", { schemeDetails });
						resolve();
					}).catch(errMsg => reject(errMsg));
				})
			}
			else {
				return new Promise((resolve, reject) => {
					axios.patch(api.schemeDetailsURL.replace("{id}",actionObject.schemeData.id	),
						actionObject.schemeData,{
							headers: {
								'Content-Type': 'multipart/form-data;boundary=----WebKitFormBoundary7MA4YWxkTrZu0gW'
							}
						}
					).then(schemeDetails => {
						commit("updateSchemeDetails", { schemeDetails });
						resolve();
					}).catch(errMsg => reject(errMsg));
				})
			}
		},
		deleteScheme({ commit }, { id, index }) {
			return new Promise((resolve, reject) => {
				api.actionHandler({
					url: api.schemeDetailsURL.replace("{id}", id),
					method: "DELETE"
				})
					.then(() => {
						commit("deleteScheme", index);
						resolve();
					})
					.catch(errMsg => reject(errMsg));
			});
		}
	}
};
